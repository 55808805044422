import { AppBar, Box, Toolbar, Typography, Menu, Grid, Divider, Button } from "@mui/material"
import { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { NavMenuReport } from "./NavMenuReport";
import { useMsal } from "@azure/msal-react";


export const NavBar = ({ reportName }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl({ [event.currentTarget.id]: event.currentTarget });
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const styles = {
        menuButton: {
            // flexGrow: 1,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            mr: 3
        },
        menuItemCircleV1: {
            //Azul
            color: '#398EF4',
            fontSize: '7px',
            marginRight: '8px',
            title: 'Actualizado a día vencido'
        },
        menuItemCircleV2: {
            //Naranja
            color: '#FF8000',
            fontSize: '7px',
            marginRight: '8px',
            title: 'Actualizado al cierre oficial de mes'
        },
        menuItemCircleV3: {
            //Verde
            color: '#53CC80',
            fontSize: '7px',
            marginRight: '8px',
            title: 'Actualizado al último cuarto'
        },
        menuItemCircleV4: {
            //Morado
            color: '#9A4CF5',
            fontSize: '7px',
            marginRight: '8px',
            title: 'Actualizado semanalmente'
        },
    }


    const links = {
        volumen: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '4d583354-da84-4102-8c5a-08d71a44c22d', page: 'ReportSection5356e96794ed09775797', name: 'Volumen', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '852f8cfb-812f-474b-85c3-9f4340859f7c', page: 'ReportSectionb227785ae8f98b9d58ab', name: 'Diario Producido', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '99353121-5b8a-438b-9313-cb33b62d1781', page: 'ReportSection393ebbb9dba3208720c7', name: 'VAPS', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'ac69d3de-aadf-4d66-b3f2-928884646acf', page: 'ReportSection5356e96794ed09775797', name: 'Productos Sostenibles', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'e9d3a3ca-897a-4724-95f6-cb585bdab439', page: 'ReportSection7588a5e148680cb4a9b3', name: 'Ofertas de Valor', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'b1b74217-2b11-46a9-8ee9-8330c90a95e2', page: 'ReportSection32168f7358f691c3f31d', name: 'Colaborado', style: styles.menuItemCircleV4 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '87a31011-2579-4871-bbcc-5c96a6b937ed', page: 'ReportSection1918ce6f9a016e52a773', name: 'Contratación', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '4b7973e0-f10d-4787-ada8-f0243f3abcb4', page: 'ReportSectionfbc79b530f9816921f33', name: 'Multiproductos', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'dd397e73-9e38-4b3e-a18a-9bf94d46e457', page: 'ReportSection1e3d5f57dc0e81737e47', name: 'Cotizaciones MPS', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'eb92adda-514f-4f80-aef4-472301099b80', page: 'ReportSection73be49b3a74849b00594', name: 'Concreto para Ti', style: styles.menuItemCircleV1, hideFilters: false },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '2bcf0e74-ecb4-4baf-b9f6-4b94ae46f19f', page: 'ReportSection934226ed923480b5ba8d', name: 'Cobertura', style: styles.menuItemCircleV1 },

        ],
        precio: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '4d583354-da84-4102-8c5a-08d71a44c22d', page: 'ReportSection9865285043c882eb2087', name: 'Precio', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'd0801c6e-cdcf-467a-a70f-0dda07050661', page: 'ReportSection', name: 'Evolución de Precios', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '4124ec3f-787b-4335-8fee-484d25ee37e5', page: 'ReportSection1fbf878bb1cac0f74dc9', name: 'Aportación Precio', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '0cfedcbb-042c-42b7-af66-6a7dcf84f9d7', page: 'ReportSectionabaf43e35d52e51019a3', name: 'Cartera', style: styles.menuItemCircleV1 },
        ],
        nps: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'd78c7ab9-2c83-49a5-be8b-232b4203aa12', page: 'ReportSection231880c2107689703487', name: 'NPS', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '97a402ef-9ca6-4288-82ff-2d7b6186e787', page: 'ReportSection', name: 'Puntualidad', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'b76c1941-a0a7-44ef-803e-24edf0a582a1', page: 'ReportSectionc784ac9aaa26a2323e66', name: 'Disponiblidad', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '03285a45-28b8-4d61-82bf-c01c304d7097', page: 'ReportSectionb6ee30aea1c3c6410691', name: 'Oportunidades de Servicio', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '64bba360-0bb0-4533-87f5-d082abaac0f9', page: 'ReportSection14fdef4827930a483ea6', name: 'Adopción CEMEX Go', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'accffad0-c183-4e62-8576-2992b4e235bc', page: 'ReportSection998438c7ce8d57d30502', name: 'Estado de Cuenta Sostenible', style: styles.menuItemCircleV2, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '71cf83ab-1f52-4247-af98-3ec79a597d84', page: 'ReportSection', name: 'Pumping Team', style: styles.menuItemCircleV1, hideFilters: true },
        ],
        operacion: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '09e188f4-13f5-4c93-8618-fc4308fec984', page: 'ReportSection744439a259268ca9b4a3', name: 'Productividad Operativa', style: styles.menuItemCircleV2, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '10cbca4e-a1fb-4039-a6a1-1e5a518223fa', page: 'ReportSection', name: 'Stock Out', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '91ca6098-8fd9-4254-a52e-440e59726f18', page: 'ReportSection', name: 'Paros de Planta', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '4358f1ac-7af6-431a-9ec1-80592fecae7c', page: 'ReportSection455cff2ec650bca4e0ba', name: 'Arranques de Planta', style: styles.menuItemCircleV1, hideFilters: true },

        ],
        calidad: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'c2eee3da-b676-42d7-a094-9c2df1d152f2', page: 'ReportSection4532847e736199bec647', name: 'Concreto devuelto', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '79910da8-d432-4dbc-a333-e741f34e7a4c', page: 'ReportSectionea170bb20e006381e321', name: 'Aseguramiento de la calidad', style: styles.menuItemCircleV1, hideFilters: true },
        ],
        sostenibilidad: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '30fc1f75-19bb-474a-91df-d7e7c31c270e', page: 'ReportSectionc8808a064694494977d1', name: 'Panel de Control Sostenibilidad', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '77cc811d-2d3b-43ba-8e5e-db48473bb4c9', page: 'ReportSection', name: 'Calculadora Vertua', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '30fc1f75-19bb-474a-91df-d7e7c31c270e', page: 'ReportSectionded44c85951bb4d00277', name: 'Resultados Sostenibilidad', style: styles.menuItemCircleV1, hideFilters: true },
        ],
        negocio: [
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '1b47a3de-d9ca-4099-9f51-24e55857900e', page: 'ReportSection', name: 'Capital de trabajo (materias primas)', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'f6502df0-20c3-4b7a-8e11-7bf203594d37', page: 'ReportSection', name: 'Costo unitario DNOM', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: 'd8ff80a6-364f-46df-acb7-f2d33243572f', page: 'ReportSectione00533c46612164d1eac', name: 'FOP Incurridos', style: styles.menuItemCircleV1 },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '71cf83ab-1f52-4247-af98-3ec79a597d84', page: 'ReportSection', name: 'EBIT', style: styles.menuItemCircleV1, hideFilters: true },
            { ctid: '6ee19001-d0c4-45f8-af8b-ff00f16d07e1', id: '1069e133-c177-4d01-94eb-0834e85e6234', page: 'ReportSectionbb008bf8124c465ec0a6', name: 'Opex México', style: styles.menuItemCircleV1, hideFilters: true },            
            //{ ctid: 'd4f3b633-0979-428f-b9cb-f9472b4e638c', id: 'tableau-viz', src: 'https://datavisualization.cemex.com/t/InteligenciaComercialMX/views/ReporteMensualConstructores-Acumulado2024/Resumen', name:'PDM', style: styles.menuItemCircleV1, hideFilters: true }
        ]
    }

    // eslint-disable-next-line
    const { instance, accounts, inProgress } = useMsal();

    return (<Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
            <Toolbar>
                <Box component="a" href="/" sx={{ mr: 4 }}>
                    <img src={"/images/Datacreto isotipo.png"} alt="" style={{ width: '65px' }} />
                </Box>

                <Typography
                    variant="h5"
                    component="div"
                    sx={{ ...styles.menuButton, flexGrow: 1, cursor: 'initial' }}
                >
                    {reportName ? reportName : 'DATACRETO'}
                </Typography>

                <Typography
                    variant="subtitle1"
                    component="div"
                    sx={styles.menuButton}
                    id="comercial-button"
                    aria-controls={open ? 'comercial-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {"Comercial "}
                    {Boolean(anchorEl && anchorEl["comercial-button"]) ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </Typography>
                <Typography
                    variant="subtitle1"
                    component="div"
                    sx={styles.menuButton}
                    id="operacion-button"
                    aria-controls={open ? 'operacion-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {"Operación "}
                    {Boolean(anchorEl && anchorEl["operacion-button"]) ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </Typography>
                <Typography
                    variant="subtitle1"
                    component="div"
                    sx={styles.menuButton}
                    id="negocio-button"
                    aria-controls={open ? 'negocio-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {"Negocio "}
                    {Boolean(anchorEl && anchorEl["negocio-button"]) ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </Typography>
                <Button variant="text" onClick={() => instance.logoutPopup()}>Logout</Button>
            </Toolbar>
        </AppBar>


        <Menu
            id="comercial-menu"
            anchorEl={anchorEl && anchorEl["comercial-button"]}
            open={Boolean(anchorEl && anchorEl["comercial-button"])}
            MenuListProps={{ 'aria-labelledby': 'comercial-button' }}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            slotProps={{ paper: { style: { minWidth: 120 } } }}
        >
            <Grid container spacing={12} sx={{ pl: 4, pr: 4, pt: 2, pb: 2 }}>
                <Grid item>
                    <Typography><strong>Volumen</strong></Typography>
                    <Divider />
                    {links.volumen.map(report => {
                        return <NavMenuReport report={report} key={report.id}/>
                    })}
                </Grid>
                <Grid item>
                    <Typography><strong>Precio</strong></Typography>
                    <Divider />
                    {links.precio.map(report => {
                        return <NavMenuReport report={report} key={report.id}/>
                    })}
                </Grid>
                <Grid item>
                    <Typography><strong>Experiencia al cliente</strong></Typography>
                    <Divider />
                    {links.nps.map(report => {
                        return <NavMenuReport report={report} key={report.id}/>
                    })}
                </Grid>
            </Grid>
        </Menu>


        <Menu
            id="operacion-menu"
            anchorEl={anchorEl && anchorEl["operacion-button"]}
            open={Boolean(anchorEl && anchorEl["operacion-button"])}
            MenuListProps={{ 'aria-labelledby': 'operacion-button' }}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            slotProps={{ paper: { style: { minWidth: 120 } } }}
        >
            <Grid container spacing={12} sx={{ pl: 4, pr: 4, pt: 2, pb: 2 }}>
                <Grid item>
                    <Typography><strong>Operaciones</strong></Typography>
                    <Divider />
                    {links.operacion.map(report => {
                        return <NavMenuReport report={report} key={report.id} />
                    })}
                </Grid>
                <Grid item>
                    <Typography><strong>Calidad</strong></Typography>
                    <Divider />
                    {links.calidad.map(report => {
                        return <NavMenuReport report={report} key={report.id} />
                    })}
                </Grid>
                <Grid item>
                    <Typography><strong>Sostenibilidad</strong></Typography>
                    <Divider />
                    {links.sostenibilidad.map(report => {
                        return <NavMenuReport report={report} key={report.id} />
                    })}
                </Grid>
            </Grid>
        </Menu>


        <Menu
            id="negocio-menu"
            anchorEl={anchorEl && anchorEl["negocio-button"]}
            open={Boolean(anchorEl && anchorEl["negocio-button"])}
            MenuListProps={{ 'aria-labelledby': 'negocio-button' }}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            slotProps={{ paper: { style: { minWidth: 120 } } }}
        >
            <Box sx={{ pl: 4, pr: 4, pt: 2, pb: 2 }}>
                {links.negocio.map(report => {
                    return <NavMenuReport report={report} key={report.id} />
                })}
            </Box>
        </Menu>

        
    </Box>)
}
